/* ContactPage.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust height as needed */
    background: rgb(192, 192, 192);

  }
  
  .container h2 {
    margin-bottom: 50px;
    color: black;
    border: 3px solid black;
    padding: 10px;
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .input-group {
    display: flex;
    gap: 40px;
    width: 500px;
    margin-bottom: 20px;
  }
  
  .input-group input,
  .message-input {
    background: none;
    padding: 8px;
    width: 100%;
    border: none;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
  }
  
  .message-input {
    height: 150px;
    resize: vertical;
    padding: 8px;
  }
  
  .submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background: none;
    color: black;
    border: 3px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-self: center;
  }
  
  .submit-button:hover {
    background-color: black;
    color: white;
  }
  