/* styles.css */
.skill-list {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .skills-heading {
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
  
  .skill-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
  }
  
  .skill-card {
    flex: 1 0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    max-width: 250px;
    max-height: 300px;
    text-align: center;
  }
  
  .skill-card:hover {
    scale: 1.1;
    cursor: pointer;
  }
  
  .skill-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .skill-card h2 {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 5px;
  }
  
  .skill-card p {
    text-align: center;
    color: #777;
    font-size: 0.9rem;
    line-height: 1.3;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .navigation-buttons button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  body{
    background-color: white;
  }
  