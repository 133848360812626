/* Container setup */
.content {
    margin-top: 0rem; /* Adjust this value based on the height of your navbar */
    margin-left: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    animation: fadeIn 1.5s ease-in-out;
}

/* Intro section on the left */
.intro {
    flex: 1;
    padding-right: 2rem;
    z-index: 2; /* Ensures the content is above the pseudo-element */
}

/* Profile picture on the right */
.profile-pic {
    flex-shrink: 0;
    z-index: 2; /* Adjusted to be behind the pseudo-element */
}

/* Adjust the image size to make it look better */
.profile-pic img {
    width: 440px;
    height: auto;
    object-fit: contain; /* Ensure entire image is visible */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin-bottom: 20px;
}

.profile-pic img:hover {
    transform: scale(1.05);
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 60-degree line pseudo-element */
.content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(60deg, transparent 50%, rgba(0, 0, 0, 0.1) 50%);
    z-index: 1; /* Ensures the pseudo-element is below the content */
}

/* Responsive design */
@media (max-width: 768px) {
    .content {
        flex-direction: column;
        align-items: center;
    }
    .intro {
        padding-right: 0;
        text-align: center;
    }
    .profile-pic {
        margin-top: 1rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .intro {
        font-size: 1.2rem; /* Adjust font size for medium-sized screens */
    }
}

@media (min-width: 1025px) {
    .intro {
        font-size: 1.5rem; /* Adjust font size for larger screens */
    }
}
.Icon:hover{
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 60px;
    margin: 0;
}