.Main {
  font-family: Arial, sans-serif;
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
}

.Portfolio-section,
.skills-section,
.contact-section,
.aboutme-section {
  padding: 20px;
}

.contact-section,
.aboutme-section {
  background: rgb(192, 192, 192);
}
