/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Basic styling for the entire about me page */
  .about-me-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    padding: 20px;
    background: rgb(192, 192, 192);
  }
  
  /* Styling for header section */
  .about-me-header {
    color: black;
    padding: 1rem;
    text-align: center;
    width: 100%;
  }
  
  .about-me-header h1 {
    font-size: 2rem;
  }
  
  /* Styling for main content */
  .about-me-content {
    display: flex;
    justify-content: space-evenly; /* Align items inline */
    flex-wrap: wrap;
    margin-top: 20px;
    justify-items: center;
  }
  .design{
    min-width: 516px;
    min-height: 177px;

  }
  .development{
    min-width: 483px;
    min-height: 178px;
  }
  
  .about-me-section {
    flex: 1 1 300px; /* Flex-grow, flex-shrink, flex-basis */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    min-width: 45%;
    padding: 50px;
  }
  .maintenance{
    min-width: 487px;
    min-height: 182px;
  }
  
  .about-me-section h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-me-section p {
    color: #666;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .about-me-content {
      flex-direction: column; /* Stack sections vertically on small screens */
      align-items: center;
    }
  }
  