/* Navbar.css */

.navbar {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  z-index: 1000; /* Ensure it stays on top of other content */
}

.navbar .nav-links {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center; /* Align items vertically */
}

.navbar .nav-links ul {
  display: flex;
  list-style-type: none; /* Remove list styling */
  flex-wrap: wrap;
  margin: 10px;
  padding: 0;
}

.nav-links ul li {
  margin-right: 20px; /* Adjust spacing between list items */
}

.nav-links ul li:last-child {
  margin-right: 0; /* Remove margin from the last list item */
}

.nav-links ul li p {
  color: black;
  text-decoration: none;
  font-size: large;
  cursor: pointer;
  padding: 10px;
}

.nav-links ul li p:hover {
  background-color: #333333; /* Darken background color on hover */
  color: white; /* Adjust text color on hover */
  transition: background-color 0.2s ease, color 0.2s ease; /* Smooth transition */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); /* Add subtle shadow on hover */
  border-radius: 5px;
}

.nav-links .btn {
  background: black;
  color: white; /* Adjust button text color */
  border: none;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust button font size */
  text-decoration: none; /* Remove underline */
  cursor: pointer;
}

.nav-links .btn:hover {
  background-color: #333333; /* Darken button color on hover */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); /* Add subtle shadow on hover */
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
  }

  .navbar .nav-links {
    width: 100%;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .navbar .nav-links ul {
    flex-direction: column;
    width: 100%;
  }

  .nav-links ul li {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .nav-links ul li p {
    width: 100%;
    text-align: center;
  }

  .nav-links .btn {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.5rem 0.25rem;
  }

  .navbar .nav-links ul {
    padding: 0;
    margin: 0;
  }

  .nav-links ul li p {
    padding: 5px;
    font-size: medium;
  }

  .nav-links .btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
